.main{
    max-width: 1200px;
    height: auto;
    margin: auto;
}
.f
{
    display: flex;
    gap: 0 5rem;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 1rem;
}
.f-div{
    width: auto;
    display: flex;
    gap: 0 0.5rem;
    flex: 1 1 150px;
    margin: 1rem 0;
}
.f-div img{
    width: 42px;
    height: 42px;
}
.f-div div p:first-child{
    font-size: large;
    font-weight: 600;
    color: #6E8C20;
}
.fl{
    color: #565656;
}
@media screen and (max-width:600px){
    .f-div{
        flex: 1 1 130px;   
    }
    .f{
        height: 100%;
        gap: 0 1rem;
        margin-left: 2rem;
    }
    .main
    {
        width: 100%;
        display: flex;
        justify-content: center;
        margin: 0;
        height: auto;
    }
}
@media screen and (max-width:300px){
    html{
        font-size: 10px;
    }
}