.hero img
{
    width: 100%;
   height: auto;
}
@media screen and (max-width:600px) {
    .hero img{
        margin-bottom: 10rem;
        margin-top: 5rem;
    }
}