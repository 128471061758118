.filter-card
{
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;
    height: auto;
}
.filter{
    margin-top: -14rem;
    width: 70%;
    height: auto;
    background-color: white;
    border-radius: 0.8rem;
    padding: 2rem 4rem;
    margin-bottom: 2rem;
}
.filter-Header
{
    display: flex;
    max-width: 500px;
    justify-content: space-between;
    list-style: none;
}
.filter-Header li{
    font-weight: 600;
    cursor: pointer;
    color: #565656;
}
.hover-h::after {
    content: '';
    display: block;
    width: 0;
    height: 2.5px;
    background: #6E8C20;
    transition: width .3s;
    margin-top: 1px;
}
.hover-h:hover::after {
    width: 100%;
}
.Filter-button{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    flex-wrap: wrap;
} 
.Filter-button div{
    padding: 1rem;
    border: 1px solid #f0f0f0;
    border-radius: 0.8rem;
    flex: 1 1 200px;
    margin:10px;
    cursor: pointer;
    color: #565656;
}
.Filter-button div p:first-child
{
    font-weight: 600;
}
.Filter-button div p i{
    font-size: 1.2rem;
    padding-left:0.3rem;
}
.Filter-button button
{
    padding: 1rem 2.5rem;
    border: none;
    background-color: #6E8C20;
    color: white;
    font-size: large;
    letter-spacing: 0.5px;
    border-radius: 0.8rem;
    width: 100%;
    cursor: pointer;
} 
.Filter-button div:hover
{
    background-color: #f0f0f082;
}
button:hover{
    background-color: #56730d;
    transition: 0.3s ease-in-out;
}
@media screen and (max-width:600px) {
    .filter
    {
        width: 100%;
    }
}
@media screen and (max-width:400px) {
    .filter{
       padding: 2rem 1.5rem;
    }
}