.main-form
{
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    font-family: Inter;
}
.login-main{
    width: 500px;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem 3rem;
    border-radius: 0.8rem;
    background: rgb(255, 255, 255);
}
.login-main img{
    width: 150px;
    height: auto;
    margin: 0;
    padding: 0;
}
.login-main h4{
    font-weight: 400;
    margin: 0.5rem 0;
}
.login-main input{
    width: 100%;
    min-height: 2.7rem;
    height: 2.5rem;
    margin: 1rem 0rem 0.4rem 0rem;
    padding: 0 1rem;
    outline: #6E8C20;
    font-family: Inter;
    border: 1px solid #6E8C20;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
    border-radius: 6px;
}
.for{
    align-self: start;
    font-size: 0.8rem;
    margin: 0.1rem 0rem 0.5rem 0rem;
    cursor: pointer;
}
.login-main button{
    width: 100%;
    min-height: 2.7rem;
    height: 2.5rem;
    border: none;
    background-color: #6E8C20;
    color: white;
    font-size: 0.9rem;
    font-weight: 400;
    font-family: Inter;
    margin: 1rem 0;
    cursor: pointer;
    filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.1));
    border-radius: 6px;
}
.for span{
    color: #6E8C20;
    cursor: pointer;
}
.lin{
    font-size: 0.9rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}
hr{
    width: 100%;
    margin-top: 0.5rem;
    filter: opacity(20%);
}
.flex-t-login
{
    display: flex;
    list-style: none;
    margin-top: 0.8rem;
    margin-bottom: 0.5rem;
}
.flex-t-login li{
    padding: 0.5rem 1rem;
    border: 1px solid #6E8C20;
    margin: 0 0.5rem;
    border-radius: 42px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
    cursor: pointer;
}
@media screen and (max-width:600px) {
    .main-form
    {
        background: white;
    }
}