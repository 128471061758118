.intro-main
{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    margin-top: 5rem;
    margin-bottom: 5rem;
}
.intro-div
{
    width: 70%;
    height: auto;
    background-color: white;
    border-radius: 0.8rem;
    border: 1px solid #F5F5F5;
    display: flex;
    flex-wrap: wrap;
}
.intro-sec
{
    flex: 1 1 400px;
}
.intro-div img{
    width: 50%;
    height: auto;
}
.intro-div div{
    width: 50%;
    padding: 5rem;
}
.intro-div div li{
    margin: 2rem 0rem;
    text-align: left;
    font-weight: 700;
    color: #565656;
}
.intro-div div h1
{
    font-weight: 500;
    margin-bottom: 2.5rem;
    font-size: 2.5rem;
    color: #565656;
}
.intro-ul
{
    list-style-image: url('../assets/check.svg');
    margin-left: 1.6rem;
}
.intro-btn
{
    padding: 0.8rem 2rem;
    border: none;
    border-radius: 0.8rem;
    background-color: #6E8C20;
    color: white;
    font-size: medium;
    margin-top: 1rem;
}
@media screen and (max-width:600px) {
    
.intro-div
{
    width: 95%;
    padding: 0;
}
.intro-div div{
    padding: 1rem 2rem;
    margin-bottom: 3rem;
}
.intro-div div li
{
    margin:2rem 0;
    font-size: 1.2rem;
}
}