.mf-main
{
    max-width: 1200px;
    margin: auto;
    text-align: center;
    height: auto;
}
.mf-main h2
{
    font-size: 3rem;
    font-weight: 400;
    color: #565656;
    margin: 2rem 1rem 0 0;
}
.mf-main h3{
    color: #565656;
    font-weight: 700;
    margin: 1.5rem 1.5rem 3rem 1.5rem ;
}
.mf-main h3 span{
    color: #6E8C20;
}
.mf-main div img{
    width: 150px;
}
.mf-flex{
    display: flex;
    gap:0 2rem;
    justify-content: center;
    flex-wrap: wrap;
}
.mf-flex div
{
    margin: 1rem;
    flex: 1 1 100px;
}
.mf-flex div h4{
    margin-top: 1rem;
    color: #565656;
}