.footer
{
    width: 100%;
    height: auto;
    background: #6E8C20;
    padding: 3rem;
    color: white;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.Item1
{
    flex: 1 1 300px;
    width: 20%;
}
.footer div p 
{
    margin: 1rem 0;
}
.Item1 div{
    margin-top: 1rem;
}
.Item1 div img{
    width: 30px;
    height: auto;
    margin: 1rem 0.8rem
}
.footer-con
{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 55%;
}
.footer-con div{

    flex: 1 1 100px;
    margin: 1rem;
    font-size: 1.1rem;
}
.footer-con div p a{
    color: white;
}
.Item1 h3 a
{
    color: white;
}
@media screen and (max-width:600px){
    .footer
    {
        justify-content: center;
    }
    .Item1
    {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .Item1 h3 a
    {
        color: white;
    }
    .Item1 h3{
        margin-top: 1rem;
        font-size: 1.3rem;
    }

    .footer-con
    {
        text-align: center;
        margin-top: 3rem;
        width: 100%;
    }
}