.main-list{
    margin-bottom: 10rem;
}
.list-header{
    display: flex;
    width: 100%;
    height: 9rem;
    padding-top: 5.5rem;
    justify-content: space-between;
    align-items: center;
}
.list-header ul li a{
    color: #383838;
}
.main-list li{
    margin: 0rem 2rem;
}
.list-head{
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    list-style: none;
}
.list-header p{
    padding-right:2rem ;
}
.list-content{
    display: flex;
    width: 100%;
    height: 100vh;
}
.list-card{
    padding: 1rem 2.5rem;
    width: 60%;
}
.list-map{
    width: 40%;
}
.listcard-item
{
    display: grid;
    grid-template-columns: repeat(3,15rem);
    grid-template-rows: repeat(2,22rem);
    width: 100%;
    height: 100%;
    gap: 2rem;
}
.list-item
{
    width: 100%;
    height: auto;
    background: #FCFCFC;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.03);
    border-radius: 0px 0px 8px 8px;


}
.price-tag
{
    font-weight: 600;
    color: #383838;
    margin-top: 1.1rem;
    font-size: large;
}
.list-item img{
    width: 100%;
}
.list-item h3{
    font-weight: 600;
    color: #383838;
    margin: 0rem 0rem;
}
.list-item p{
    font-weight: 300;
    color: #383838;
    font-size: 0.8rem;
    margin: 0.1rem 0rem;
}
.list-card-title
{
    margin-left: 0.8rem;
    margin-top: 0.3rem;
}
.list-item h3 span{
    font-size: 0.7rem;
}
.list-card-title div button
{
    padding: 0.2rem 0.6rem;
    background-color: #6E8C20;
    border: none;
    color: white;
    font-size: 0.7rem;
    font-weight: 500;
    margin-right: 1rem;
    margin-top: 0.5rem;
    border-radius: 42px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
    border-radius: 27px;
}
.list-map{
    background: url(../assets/map.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.item-header
{
    display: flex;
    justify-content: space-between;
}
.list-item-header
{
    display: flex;
    justify-content: space-between;
    margin:1rem 2.5rem;
    cursor: pointer;
}