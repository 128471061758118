.cointainer
{
    background-color: white;
}
.head
{
    position: fixed;
    z-index: 1000;
    display: flex;
    justify-content: center;
    /* overflow: hidden; */
    width: 100%;
    height: 5rem;
    background-color: white !important;
    box-shadow: 2px 0px 4px rgb(132, 132, 132);

    
}
.fixed{
    
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1400px;
    width: 100%;
}
.menu-list
{
    min-width: 300px;
    display: flex;
    width: 400px;
    justify-content: space-between;
    align-items: center;
    list-style: none;
}
.menu-list li a{
    color: #383838;
    font-weight: 600;
}
.list-t
{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 162px;
    height: 40px;
    border: 1px solid #6E8C20;
    border-radius: 20px;
}
.list-t a{
    padding: 1rem;
}
.logo
{
    height: 6rem;
    margin-top: 1.5rem;
    width:auto;
}
.menu-list div{
    display: flex;
    min-width: 100px;
    width: 200px;
    justify-content: space-between;
    align-items: center;
    list-style: none;
}
.icon{
    display: none;
}
.d-none{
    display: none;
}
.d-none li{
    display: none;
}
.hover-h::after {
    content: '';
    display: block;
    width: 0;
    height: 2.5px;
    background: #6E8C20;
    transition: width .3s;
    margin-top: 1px;
}
.hover-h:hover::after {
    width: 100%;
}
.list-t:hover
{
    background-color: #6E8C20;
    transition: 0.3s ease-in-out;
}
.list-t a:hover{
    color: white;
    transition: 0.3s ease-in-out;
}
.menu-link-a
{
    display: none;
 
}
.menu-link-li
{
    list-style: none;
}
@media screen and (max-width:600px) {
    
    .menu-link-a
{
    display: block;
}
    .menu-list div
    {
        display: none;
    }
    .menu-list
    {
        justify-content: flex-end;
        align-items: center;
        min-width: 100px;
    }
    .icon{
        display: block;
        font-size:2rem;
        margin: 1rem;
        margin-top: 1.3rem;
        color: #6E8C20;
    }
    /* .logo
    {
        width: 150px;
        height: auto;
    } */
    html{
        font-size: 13px;
    }
    .flot-right
    {
        display: flex;
        justify-content: flex-end;
    }
    .d-none{
    display: block;
    }
    .menu{
        width: 200px;
        display: flex;
        flex-direction: column;
        height: 100vh;
        top: 65px;
        position: fixed;
        z-index: 10000;
        background-color: #6E8C20;
        list-style: none;
        transition: 0.15s ease-in-out;
        right: -300px;
        padding: 1rem;
    }
    .width{
        right: 0px;
    }
    .menu li a{
        font-size: 1.7rem;
        color: white;
        font-weight: 500;
    }
    .menu li{
            margin: 0.5rem 1rem;
    }
    .head{
        justify-content: flex-end;
    }
}
